import {
  AddViewCountProps,
  FetchRetrieveEmbedProps,
  hoverClickCountProps,
} from "../interfaces/apiPayloadInterfaces";
import { Axios, urls } from "./Axios";

export const FetchRetrieveEmbed = ({ params }: FetchRetrieveEmbedProps) =>
  Axios.get(urls.embedRetrieve, { params });

export const AddViewCount = ({ payload }: AddViewCountProps) =>
  Axios.post(urls.viewCount, payload);

export const CountObjectHoverAndClick = ({ payload }: hoverClickCountProps) =>
  Axios.post(urls.clickHoverCount, payload);

export const GetIpAddress = () => Axios.get(urls.getIpAddress);
