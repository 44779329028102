import React, { useContext, useEffect, useState } from "react";
// import Stage from "./components/BaseModel/Stage";
// import BaseModel from "./components/BaseModel/BaseModel";
import { FetchRetrieveEmbed } from "./urls/retrieveEmbedRoutes";
import AppContext from "./states/createContext";
import { BrowserRouter, Route, useSearchParams } from "react-router-dom";
import { UUID } from "crypto";
import AppContextProvider from "./states/context";

import "./assets/scss/App.scss";
import BaseTooltip from "./components/Tooltips/BaseToolTip";
import {
  HostDataProps,
  segmentedObjectsProps,
} from "./interfaces/stateInterfaces";
import axios from "axios";

function IndexApp() {
  const {
    embedData: [embedData, setEmbedData],
    tooltipPoint: [tooltipPoint],
    clicks: [clicks, setClicks],
    hovers: [hovers, setHovers],
    viewsData: [viewData, setViewData],
  } = useContext(AppContext)!;

  const [searchParams, setSearchParams] = useSearchParams();
  const image_id = searchParams.get("image_id");

  const [tooltip, setTooltip] = useState<segmentedObjectsProps | null>();

  const [hostData, sethostData] = useState<HostDataProps | null>(null);

  useEffect(() => {
    // it is coming from the upload dynamic image page

    function fetchForPreview() {
      fetching();
    }
    if (searchParams.get("type") === "preview") {
      const segmentObjectData = searchParams.get("objects");
      if (!segmentObjectData) {
        return;
      }
    }
    if (!!searchParams.get("image_id") === false) {
      alert("no params");
      return;
    }
    async function fetching() {
      try {
        const response = await FetchRetrieveEmbed({
          params: {
            image_id: searchParams.get("image_id") as UUID,
          },
        });

        const data = {
          imageUrl: response.data.image_url,
          embeddingFileUrl: response.data.empedding_file_url,
          campaignId: response.data.campaign_id,
          segmentationData:
            response.data.segmentation_data_image?.[0]?.segmentation_data || [],
          imageObjects: response.data.image_objects.map((eachObjects: any) => {
            return {
              id: eachObjects.id,
              segmentationDataObject: [eachObjects.segmentation_data_object],
              pointers: eachObjects.pointers,
              titleCard: eachObjects.title_card,
            };
          }),
        };

        setEmbedData(data);
        return data;
      } catch (error) {
        console.log(error);
      }
      return null;
    }
    fetching();
  }, []);

  useEffect(() => {
    if (embedData) hostDataFun();
  }, [embedData]);
  useEffect(() => {
    if (hostData?.client && hostData?.host && image_id) updateViewData();
  }, [hostData, image_id]);
  const updateViewData = async () => {
    const payload = {
      user_ip: hostData?.client,
      host_url: hostData?.host,
      image_id: image_id,
    };
    try {
      const res = await axios.post(
        "https://dev-api.spat.media/api/view-count",
        payload
      );
      if (res?.status === 201) {
        setViewData(res?.data);
        localStorage.setItem("view-data", JSON.stringify(res?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (clicks && tooltip && viewData) updateClickOrHoverCount("click");
  }, [clicks, tooltip, viewData]);
  useEffect(() => {
    if (hovers && tooltip && viewData) updateClickOrHoverCount("hover");
  }, [hovers, tooltip, viewData]);

  const updateClickOrHoverCount = async (type_of_data: any) => {
    const payload = {
      view_id: viewData?.id,
      object_id: tooltip?.id,
      type_of_data,
    };
    try {
      const res = await axios.post(
        "https://dev-api.spat.media/api/click-hover-count",
        payload
      );
    } catch (error) {
      console.log(error);
    }
  };

  const hostDataFun = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log(res.data);
      sethostData({
        client: res.data.ip,
        host:
          window.location !== window.parent.location
            ? document.referrer
            : document.location.href,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const flexCenterClasses = "flex items-center justify-center";
  return embedData ? (
    <>
      <div className={`${flexCenterClasses} relative w-full h-full`}>
        <div className={`${flexCenterClasses} relative w-[90%] h-[90%]`}>
          {/* <BaseModel tooltip={tooltip} setTooltip={setTooltip} /> */}
        </div>
        {/* {tooltip && tooltipPoint && (
          <BaseTooltip toolTipData={tooltip} tooltipPoint={tooltipPoint} />
        )} */}
      </div>
      {/* {tooltip && <div>{<BaseTooltip />}</div>} */}
    </>
  ) : (
    <div>no data</div>
  );
}

function App() {
  return <IndexApp />;
}

export default App;
