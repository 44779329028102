import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../states/createContext";
import MouseTooltip from "react-sticky-mouse-tooltip";
import axios from "axios";
// import "../../index.css";
// import "../../app.css";

import {
  HostDataProps,
  HoveringRectDataProps,
  ObjectArrProps,
  ScaleDataProps,
  TooltipPointProps,
} from "../../interfaces/stateInterfaces";
import BaseTooltip from "../Tooltips/BaseToolTip";

import { useSearchParams } from "react-router-dom";
import {
  AddViewCount,
  CountObjectHoverAndClick,
  FetchRetrieveEmbed,
} from "../../urls/retrieveEmbedRoutes";
import { UUID } from "crypto";
import {
  EmbedResponseProps,
  ImageObjectsEntity,
  ViewCountDataProps,
} from "../../interfaces/apiResponseInterfaces";

export default function Embed() {
  const [searchParams] = useSearchParams();
  const showType = searchParams.get("showType");
  const imageId = searchParams.get("image_id");

  // condition states
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // svg states

  const [imageScale, setImageScale] = useState<any | null>(null);
  const [scaleData, setScaleData] = useState<ScaleDataProps | null>(null);

  // data states
  const [svgsv, setSv] = useState<any>(null);

  // image states
  const [imageData, setImageData] = useState<EmbedResponseProps | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageBlob, setImageBlob] = useState<string | null>(null);

  // refs
  const svgRef = useRef<SVGSVGElement | null>(null);

  const [oneEntered, setOneEntered] = useState<boolean | null>(null);
  const [hoverData, setHoveredData] = useState<any | null>(null);
  const [prevHover, setPrevHover] = useState<any | null>(null);

  const [imageStatus, setImageStatus] = useState<
    "published" | "draft" | "preview" | null
  >(null);

  useEffect(() => {
    if (oneEntered === false) {
      setPrevHover(null);
    }
    if (oneEntered === null) {
      return;
    }

    if (oneEntered) {
      if (!hoverData) {
        return;
      }
      let prevC;
      if (prevHover == null) {
        // prevC = hoverData;
        setPrevHover(hoverData);
      } else {
        prevC = prevHover;
      }
      if (hoverData.objectUUID !== prevC?.objectUUID) {
        if (alreadyHovered === false) {
          return;
        }
        // console.log("hovering");
        setPrevHover(hoverData);
        handleObjectHoveringCount(hoverData.objectUUID, hoverData.type);
      }
    }
  }, [hoverData, oneEntered]);

  const [retrieveData, setRetrieveData] = useState<any>(null);

  const getImageAsBase64 = async (imageUrl: string) => {
    try {
      // Infer the MIME type based on the file extension
      const extension = imageUrl.split(".").pop();
      const mimeTypes: any = {
        png: "image/png",
        jpeg: "image/jpeg",
        jpg: "image/jpeg",
        gif: "image/gif",
        // Add more as needed
      };
      const mimeType = mimeTypes[extension!.toLowerCase()];

      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });

      if (response.status === 200) {
        const arrayBuffer = response.data;
        const blob = new Blob([arrayBuffer], {
          type: mimeType || "application/octet-stream",
        });
        const reader = new FileReader();

        reader.onload = () => {
          const base64ImageData = reader.result;
          console.log(base64ImageData);
          setImageBlob(base64ImageData as string); // The image data as base64
        };

        reader.readAsDataURL(blob);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchingRetrieve() {
    try {
      const response = await FetchRetrieveEmbed({
        params: {
          image_id: imageId as UUID,
        },
      });

      setImageUrl(response.data.image_url);
      setImageData(response.data as EmbedResponseProps);
      setImageStatus(response.data.status);

      setRetrieveData(response.data);

      setImageExpired(
        showType === "published" &&
          ((response.data.status === "published" &&
            (response.data.is_active === false ||
              new Date(response.data.expiry_date) < new Date())) ||
            response.data.status === "completed")
      );

      if (!response.data?.image_objects?.[0]) {
        setImageExpired(true);
      }

      // console.log(
      //   response.data.image_objects.map((eachData: any) => {
      //     return eachData.area;
      //   })
      // );
      getImageAsBase64(response.data.image_url);

      const mappedImageObjects: ObjectArrProps[] = response.data.image_objects
        .filter(
          (eachObject: any) =>
            eachObject?.deleted === false && eachObject.is_active === true
        )
        .map((eachObjects: ImageObjectsEntity): ObjectArrProps => {
          const dd: ObjectArrProps = {
            ...eachObjects,
            isHovered: false,
            ref: null,
            boundingBox: null,
          };

          return dd;
        })
        .sort((a: ImageObjectsEntity, b: ImageObjectsEntity) => {
          if (b?.area < a?.area) {
            return -1; // b should come before a
          } else if (b?.area > a?.area) {
            return 1; // a should come before b
          } else {
            return 0; // a and b have equal areas, their order doesn't matter
          }
        });

      // console.log(
      //   "after sorting",
      //   mappedImageObjects.map((eachData: any) => {
      //     return eachData.area;
      //   })
      // );

      if (mappedImageObjects) {
        setScaleData({
          xScale:
            mappedImageObjects[0].model_scale.width *
            mappedImageObjects[0].model_scale.upload_scale,
          yScale:
            mappedImageObjects[0].model_scale.height *
            mappedImageObjects[0].model_scale.upload_scale,
        });
      }
      setObjectArr(mappedImageObjects);
    } catch (error) {
      console.log("fetching retrieve api error", error);
    }
  }

  const [hostData, sethostData] = useState<HostDataProps | null>(null);
  const [viewCountData, setViewCountData] = useState<ViewCountDataProps | null>(
    null
  );

  async function handleSettingHostData() {
    const res = await axios.get("https://api.ipify.org/?format=json");

    //

    sethostData({
      client: res.data.ip,
      host:
        window.location !== window.parent.location
          ? document.referrer
          : document.location.href,
    });

    //
  }

  async function loadViewCount() {
    if (hostData === null) {
      return;
    }
    const response = await AddViewCount({
      payload: {
        host_url: hostData.host,
        image_id: imageId as UUID,
        user_ip: hostData.client,
      },
    });

    setViewCountData(response.data);
    setIsLoading(false);
  }
  console.log("asdfaosfasodfjoiasjfdoiajfoiasjdfoij");
  useEffect(() => {
    if (hostData === null) {
      return;
    }
    if (showType !== "published" && imageStatus !== "published") {
      setIsLoading(false);
      return;
    }
    if (showType === "published" && imageStatus === "published") {
      loadViewCount();
    }
  }, [hostData, imageStatus]);

  useEffect(() => {
    console.log("Component mounted");
    const handleOrientationChange = () => {
      console.log("Orientation changed");
      // setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    if (
      showType === "draft" ||
      showType === "published" ||
      showType === "preview"
    ) {
      if (imageId === null) {
        alert("image id needed");
        return;
      }
      fetchingRetrieve();
      handleSettingHostData();
    }

    return () => {
      console.log("Component unmounted");
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipPointProps | null>(null);

  const baseRef = useRef<HTMLDivElement>(null);
  // const svgRef = useRef<SVGImageElement | null>(null);

  const [objectArr, setObjectArr] = useState<ObjectArrProps[] | null>(null);
  const [hover, setHover] = useState<number | null>(null);
  const [titleCardDetails, setTitleCardDetails] = useState<any>(null);
  const [hoveringRectData, setHoveringRectData] =
    useState<HoveringRectDataProps | null>(null);

  const [svgRefSeparateData, setSvgRefSeparateData] =
    useState<SVGSVGElement | null>(null);

  const [alreadyHovered, setAlreadyHovered] = useState<boolean>(false);

  const id = Math.random();

  async function handleObjectHoveringCount(
    objectUUID: UUID,
    type: "click" | "hover"
  ) {
    if (imageStatus !== "published" || showType !== "published") {
      return;
    }
    if (viewCountData === null) {
      alert("view count data not available");
      return;
    }

    try {
      const response = await CountObjectHoverAndClick({
        payload: {
          object_id: objectUUID,
          type_of_data: type,
          view_id: viewCountData.id,
        },
      });

      // console.log("counted", type, response.data);
    } catch (error) {
      console.log(
        "something went wrong in CountObjectHoverAndClick api",
        error
      );
    }
  }

  useEffect(() => {
    if (!svgRef?.current) {
      return;
    }
    setImageScale({
      width: svgRef?.current.width,
      height: svgRef.current.height,
    });
  }, [svgRef]);

  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout[] | null>(null);
  const [firstTimeout, setFirstTimeout] = useState<NodeJS.Timeout | null>(null);

  const rollNotifyRef = useRef<HTMLDivElement>(null);

  const [intervalRoller, setIntervalRoller] = useState<number>(0);

  const [startInterval, setStartInterval] = useState<boolean>(false);

  const [rollerIntervalId, setRollerIntervalId] = useState<NodeJS.Timer | null>(
    null
  );

  const [rollerIntervalRefresh, setRollerIntervalRefresh] = useState();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!imageBlob) {
      return;
    }
    if (!objectArr) {
      return;
    }
    if (!svgRef.current) {
      return;
    }

    setAlreadyHovered(false);

    // let d = 2000;
    // for (let objectIdx = 0; objectIdx < objectArr?.length; objectIdx++) {
    //   d += objectIdx * 1500;
    // }

    // setInterval(() => {
    //   setTimeout(() => {}, 1500);
    //   for (let objectIdx = 0; objectIdx < objectArr?.length; objectIdx++) {
    //     const tf = () => {
    //       setHover(objectIdx);
    //       setOneEntered(true);
    //       setHoveredData({
    //         objectUUID: objectArr[objectIdx].id as UUID,
    //         type: "hover",
    //       });
    //       // console.log("i am ", objectIdx);
    //     };

    //     const timeOutId = setTimeout(tf, objectIdx * 1500);
    //     setTimeoutIds((prev) =>
    //       prev !== null ? [...prev, timeOutId] : [timeOutId]
    //     );
    //   }

    //   setFirstTimeout(
    //     setTimeout(() => {
    //       setHover(null);
    //     }, d)
    //   );
    // }, d);
    // console.log("intervalRoller", intervalRoller);
    console.log("jadsfjsdf");
    setStartInterval(true);
  }, [imageBlob, isLoading, objectArr, svgRef.current]);

  function startingIntervalFun() {
    if (!startInterval) {
      return;
    }
    if (!objectArr) {
      return;
    }
    function intervalCallback(len: number) {
      setIntervalRoller((prev) => {
        console.log("prev", prev);
        const clonePrev = prev + 1;
        if (clonePrev > len - 1) {
          return 0;
        }
        return clonePrev;
      });
    }

    setHover(intervalRoller);
    setOneEntered(true);
    setHoveredData({
      objectUUID: objectArr[intervalRoller].id as UUID,
      type: "hover",
    });

    const intervalId = setInterval(intervalCallback, 3000, objectArr.length);

    setRollerIntervalId(intervalId);
  }

  useEffect(() => {
    if (!startInterval) {
      return;
    }
    if (!objectArr) {
      return;
    }
    console.log("dog");
    startingIntervalFun();
  }, [startInterval]);

  useEffect(() => {
    // console.log("intervalRoller", intervalRoller);
    if (!objectArr) {
      return;
    }
    setHover(intervalRoller);
    setOneEntered(true);
    setHoveredData({
      objectUUID: objectArr[intervalRoller].id as UUID,
      type: "hover",
    });
  }, [intervalRoller]);

  useEffect(() => {
    if (alreadyHovered === false) {
      return;
    }
    if (timeoutIds !== null) {
      timeoutIds.map((ids) => {
        clearTimeout(ids);
      });
    }
    if (firstTimeout !== null) {
      clearTimeout(firstTimeout);
    }
  }, [timeoutIds, firstTimeout, alreadyHovered]);

  useEffect(() => {
    if (!objectArr || !scaleData) {
      return;
    }

    const svgElement = document.getElementById(
      "mainSvg"
    ) as SVGSVGElement | null;
    if (svgElement) {
      svgRef.current = svgElement;
      setSvgRefSeparateData(svgElement);
      // Your code here
    }
    console.log("calling while setting", document.getElementById("mainSvg"));

    // console.log(document.getElementById("mainSvg"));
  }, [objectArr, scaleData, svgRef]);

  const [rolloverNotifyStyle, setRollOverNotifyStyle] = useState<any | null>(
    {}
  );

  const [showToller, setShowRoller] = useState<boolean>(false);

  const [imageExpired, setImageExpired] = useState<boolean>(false);

  useEffect(() => {
    if (!rollNotifyRef.current) {
      return;
    }

    if (!svgRef.current) {
      return;
    }
    if (!svgsv) {
      return;
    }

    window.addEventListener("resize", () => {
      const svgRect = svgRef?.current?.getBoundingClientRect();
      if (!svgRect) {
        return;
      }

      setRollOverNotifyStyle({
        top:
          svgRect.top +
          svgRect.height -
          (rollNotifyRef?.current?.offsetHeight || 0),
        // opacity: 1,
      });
    });

    const svgRect = svgRef.current.getBoundingClientRect();
    setRollOverNotifyStyle({
      top:
        svgRect.top + svgRect.height - 3 - rollNotifyRef.current.offsetHeight,
      opacity: 1,
    });
    setShowRoller(true);
    setTimeout(() => {
      setShowRoller(false);
    }, 2000);
  }, [svgRef, rollNotifyRef.current, svgRefSeparateData, svgsv]);

  useEffect(() => {
    console.log("calling the svg from the useEffect", svgRef);
  }, [svgRef]);

  useEffect(() => {
    if (titleCardDetails && rollerIntervalId) {
      clearInterval(rollerIntervalId);
      setRollerIntervalId(null);
    }

    if (!titleCardDetails && !rollerIntervalId) {
      setTimeout(() => {
        startingIntervalFun();
      }, 100);
    }
  }, [titleCardDetails]);

  const [isRotating, setIsRotating] = useState(false);

  useEffect(() => {
    console.log("some problem", isRotating);
  }, [isRotating]);

  return (
    <div
      className="w-full h-full max-w-[100%] max-h-[100%] relative flex justify-center items-center flex-col"
      id="whole-wrapper"
      ref={baseRef}>
      {imageExpired && (
        <img
          src={imageBlob || retrieveData.image_url}
          alt="dynamic img "
          className="w-max h-max  max-h-[100%] max-w-[100%]"
        />
      )}
      {!imageExpired &&
      !isLoading &&
      !!imageBlob === true &&
      objectArr !== null ? (
        <>
          <div className="embed-main-wrapper w-full h-full flex justify-center items-center">
            <svg
              id="mainSvg"
              ref={(ref) => {
                svgRef.current = ref;
                setSv(ref);
              }}
              onClick={() => {
                if (titleCardDetails && hover && hoverData) {
                  // console.log("yes data is there");
                }

                console.log("onMouseup");
              }}
              onMouseUp={() => {
                if (titleCardDetails && hover && hoverData) {
                  // console.log("yes data is there");
                  handleObjectHoveringCount(
                    titleCardDetails.objectUUID as UUID,
                    "click"
                  );
                }

                // console.log("onMouseup");
              }}
              onMouseEnter={() => {
                // console.log("entingring in to svg");
                setHover(null);
                setTitleCardDetails(null);

                setOneEntered(false);
              }}
              className="w-max h-max max-w-[100%] max-h-[100%]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`0 0 ${scaleData?.xScale} ${scaleData?.yScale}`}>
              <image
                width="100%"
                height="100%"
                xlinkHref={imageBlob || ""}
                clipPath={`url(#clip-path${id})`}
              />
              {objectArr?.map((eachObjects, idx: number) => {
                // console.log("rendering")
                const xScale =
                  eachObjects.model_scale.width *
                  eachObjects.model_scale.upload_scale;

                const bbX = eachObjects?.boundingBox?.x;
                const bbY = eachObjects.boundingBox?.y;
                const bbWidth = eachObjects.boundingBox?.width;
                const bbHeight = eachObjects.boundingBox?.height;
                const bbMiddleY = bbY && bbHeight && bbY + bbHeight / 2;
                const bbWidthRatio = bbWidth && bbWidth / xScale;

                return (
                  <>
                    <>
                      {bbWidthRatio && eachObjects.isHovered && (
                        <path
                          id={"mask-gradient" + eachObjects.id}
                          className={`mask-gradient ${
                            bbWidthRatio > 0.5 && window.innerWidth < 768
                              ? "hidden"
                              : ""
                          }`}
                          d={eachObjects.svg_string}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeOpacity="0"
                          fillOpacity="1"
                          fill={`url(#gradient${eachObjects.id})`}
                        />
                      )}
                    </>
                    <path
                      key={Math.random()}
                      ref={(ref) => {
                        if (ref) {
                          setObjectArr((prev: any) => {
                            const clone = prev;
                            clone.map((ea: any) => {
                              if (ea.id === eachObjects.id) {
                                ea.ref = ref;
                                ea.boundingBox = ref.getBBox();
                              }
                              return ea;
                            });
                            return clone;
                          });
                        }
                      }}
                      id={"mask-path" + eachObjects.id}
                      className="mask-path"
                      d={eachObjects.svg_string}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeOpacity={idx === hover ? 0.6 : 0}
                      fillOpacity="0"
                      stroke="white"
                      strokeWidth="3"
                      // ref={pathRef}
                      filter={`url(#glow${eachObjects.id})`}
                      // onMouseUp={() => {}}
                      onMouseOut={(e) => {
                        // console.log("mouse outing");
                        // console.log("outing");
                        setHover(null);
                        // setOneEntered(false);
                        // e.stopPropagation();
                        // setObjectArr((perv: any) => {
                        //   const clonePrev = perv;
                        //   return clonePrev.map((ea: any) => {
                        //     if (ea.id === eachObjects.id) ea.isHovered = false;
                        //     return ea;
                        //   });
                        // });
                        // e.stopPropagation();
                        // e.preventDefault();
                        // e.stopPropagation();
                        // handleTooltipShow(false, e);
                        // setShowTooltip(false);
                      }}
                      onMouseLeave={() => {
                        // console.log("mouseleave");
                        setHover(null);
                        setTitleCardDetails(null);
                        // setOneEntered(false);
                      }}
                      // onClick={() => {
                      //   // window.open(eachObjects?.title_card?.[0]?.redirect_url);
                      //   console.log("clicking");
                      // }}
                      onMouseUp={() => {
                        // console.log("mouseup from path");
                        handleObjectHoveringCount(
                          eachObjects.id as UUID,
                          "click"
                        );

                        if (
                          !!eachObjects?.title_card?.[0]?.redirect_url === true
                        ) {
                          window.open(
                            eachObjects?.title_card?.[0]?.redirect_url
                          );
                        }
                      }}
                      onMouseMove={(e) => {
                        // e.preventDefault();
                        // e.stopPropagation();
                        const el = e.target as HTMLElement;
                        const rect = el.getBoundingClientRect();
                        let x = e.clientX;
                        let y = e.clientY;

                        setHoveringRectData(rect);

                        setTooltipPosition({
                          x,
                          y,
                          parentWidth: baseRef.current?.offsetWidth ?? 0,
                          parentHeight: baseRef.current?.offsetHeight ?? 0,
                          rectLeft: rect.left,
                          rectTop: rect.right,
                        });
                      }}
                      onMouseEnter={(e) => {
                        // e.preventDefault();
                        // e.stopPropagation();
                        setAlreadyHovered(true);
                        setHover(idx);

                        setOneEntered(true);

                        setHoveredData({
                          objectUUID: eachObjects.id as UUID,
                          type: "hover",
                        });

                        // console.log(
                        //   "this is the area of hover object",
                        //   eachObjects.area
                        // );

                        setTitleCardDetails(
                          eachObjects.title_card?.[0]
                            ? {
                                ...eachObjects.title_card?.[0],
                                objectUUID: eachObjects.id,
                              }
                            : null
                        );

                        // handleObjectHoveringCount(
                        //   eachObjects.id as UUID,
                        //   "hover"
                        // );

                        // setTooltipPoint({
                        //   x:
                        // })
                        // setObjectArr((perv: any) => {
                        //   const clonePrev = perv;
                        //   return clonePrev.map((ea: any) => {
                        //     if (ea.id === eachObjects.id) {
                        //       ea.isHovered = true;
                        //     } else {
                        //       ea.isHovered = false;
                        //     }
                        //     return ea;
                        //   });
                        // });
                        // e.stopPropagation();
                        // e.preventDefault();
                        // setCustomPathId(Math.random());
                        // handleTooltipShow(true, e);
                        // setShowTooltip(true);
                      }}
                    />
                    <filter
                      id={"glow" + eachObjects.id}
                      x="-50%"
                      y="-50%"
                      width={"200%"}
                      height={"200%"}>
                      <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="2"
                        floodColor="white"
                      />
                      <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="4"
                        floodColor="hsl(112.37deg 56.19% 41.18%)"
                      />
                      <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="6"
                        floodColor="white"
                      />
                    </filter>
                  </>
                );
              })}
            </svg>
          </div>
          {hover !== null && tooltipPosition && titleCardDetails && <div></div>}

          <div className="rol-over-annotation"></div>
        </>
      ) : (
        <div>Loading...</div>
      )}
      {/* {isLoading ||
        !!imageBlob === false ||
        svgRef.current === null ||
        objectArr === null || <div>Loading...</div>} */}

      {svgRef.current && (
        <div
          ref={rollNotifyRef}
          id="rollNotify"
          className={`absolute w-max h-max px-[10px] py-[10px] min-w-[200px] min-h-[50px] max-w-[300px] max-h-[100px]  opacity-0 rolling-notify rounded-2xl flex justify-center flex-col items-center rollNotify`}
          style={{
            ...rolloverNotifyStyle,
            // ...rollerStyleSetting(),
            backgroundColor: "rgba(252, 252, 252, 0.7)",
            opacity: showToller ? 1 : 0,
          }}>
          <h3 className="text-center">Rollover to Explore</h3>
          <h6 className="text-center">Interactive Image</h6>
        </div>
      )}
    </div>
  );
}

{
  /* <BaseTooltip
hoveringRectData={hoveringRectData}
toolTipData={titleCardDetails}
tooltipPoint={tooltipPosition}
handleObjectHoveringCount={handleObjectHoveringCount}
imageScale={imageScale}
svgRef={svgRef}
pathIdx={0}
svgImageRef={null}
/> */
}
