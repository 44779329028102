import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppContextProvider from "./states/context";
import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import "./index.css";
import "./app.css";
import routers from "./routes";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);

root.render(
  <AppContextProvider>
    <RouterProvider router={routers} />
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
