import { createContext } from "react";
import { modelInputProps } from "../interfaces/helperInterfaces";
import {
  EmbedDataProps,
  TooltipPointProps,
  ViewFetchedData,
} from "../interfaces/stateInterfaces";

export interface ContextProps {
  clicks: [
    clicks: modelInputProps[] | null,
    setClicks: (e: modelInputProps[] | null) => void
  ];
  hovers: [
    hovers: modelInputProps[] | null,
    setHovers: (e: modelInputProps[] | null) => void
  ];
  clickedPoint: [
    click: modelInputProps | null,
    setClick: (e: modelInputProps | null) => void
  ];
  image: [
    image: HTMLImageElement | null,
    setImage: (e: HTMLImageElement | null) => void
  ];
  maskImg: [
    maskImg: HTMLImageElement | null,
    setMaskImg: (e: HTMLImageElement | null) => void
  ];
  embedData: [
    embedData: EmbedDataProps | null,
    setEmbedData: (e: EmbedDataProps | null) => void
  ];
  tooltipPoint: [
    tooltipPoint: TooltipPointProps | null,
    setTooltipPoint: (e: TooltipPointProps | null) => void
  ];
  viewsData: [
    viewData: ViewFetchedData | null,
    setViewData: (e: ViewFetchedData | null) => void
  ];

  // toolTip showing
  showTooltip: [showTooltip: boolean, setShowTooltip: (e: boolean) => void];
}

const AppContext = createContext<ContextProps | null>(null);

export default AppContext;
