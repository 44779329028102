import React, { useMemo, useState } from "react";
import { modelInputProps } from "../interfaces/helperInterfaces";
import AppContext, { ContextProps } from "./createContext";
import {
  EmbedDataProps,
  TooltipPointProps,
  ViewFetchedData,
} from "../interfaces/stateInterfaces";

const AppContextProvider = (props: {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
  const [clicks, setClicks] = useState<Array<modelInputProps> | null>(null);
  const [hovers, setHovers] = useState<Array<modelInputProps> | null>(null);
  const [click, setClick] = useState<modelInputProps | null>(null);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [maskImg, setMaskImg] = useState<HTMLImageElement | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  // embedData
  const [embedData, setEmbedData] = useState<EmbedDataProps | null>(null);
  const [tooltipPoint, setTooltipPoint] = useState<TooltipPointProps | null>(
    null
  );
  const [viewData, setViewData] = useState<ViewFetchedData | null>(
    JSON.parse(localStorage.getItem("view-data") || "{}")
  );
  const values: ContextProps = {
    clicks: [clicks, setClicks],
    hovers: [hovers, setHovers],
    clickedPoint: [click, setClick],
    image: [image, setImage],
    maskImg: [maskImg, setMaskImg],
    embedData: [embedData, setEmbedData],
    tooltipPoint: [tooltipPoint, setTooltipPoint],
    viewsData: [viewData, setViewData],
    showTooltip: [showTooltip, setShowTooltip],
  };

  const providerValues = useMemo(() => {
    return values;
  }, [values]);

  return (
    <AppContext.Provider value={providerValues}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
